import $ from 'jquery';
import './index.scss';
function getAnchors(SEL){
	let anchors = [];
	$(SEL).find('a[href]').each(function(){
		let href = $(this).attr('href').replace(/([!"#$%&'()*+,./:;<=>?@[\]^`{|}~])/g, "\\$1");
        let target = $(href);
		//let target = $($(this).attr('href'));
		if (target.length){
			anchors.push({
				el:target,
				top:target.offset().top,
				control: $(this)			
			})
		}
	});
	anchors = anchors.sort((a,b)=>a.top > b.top?1:-1);
	anchors = anchors.map((x,i)=>{
		x.bottom = (anchors[i+1])?anchors[i+1].top:10E10;
		return x;
	})
	return anchors;
}
$(window).scroll(function(){
	let scroll = $(window).scrollTop();
	const SEL = '.wp-block-webfactory-page-menu__inner';
	if (scroll > .75 * $(window).height() ){
		let x = $(SEL).addClass('active');
	}
	else{
		let x = $(SEL).removeClass('active');
	}
	let anchors = getAnchors(SEL);
	let current = anchors.find(x=>scroll < x.bottom && scroll < x.top);
	if (current){
		$(SEL).find('.active').removeClass('active');
		current.control.addClass('active');
	}
})